import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SearchLimitResult, SearchRequestEntities } from '@app/shared/models/api';
import { AppSettingsService, HttpService } from '@app/shared/services';
import { ApiMethods, Sections } from '@app/shared/types';
import { getSectionLink } from '@app/shared/utils';
import { LikesViewsModule } from '@comp/likes-views/likes-views.module';
import { ButtonArrowComponent } from '@shared/controls';
import { SharedModule } from '@shared/shared.module';
import { Observable } from 'rxjs';

@Component({
  selector: 'ln-banner-popular-questions',
  templateUrl: './popular-questions.component.html',
  styleUrls: ['./popular-questions.component.scss'],
  imports: [SharedModule, LikesViewsModule, ButtonArrowComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerPopularQuestionsComponent implements OnInit {
  @Input() limit = 3;

  questions$?: Observable<SearchLimitResult>;
  getSectionLink = getSectionLink;
  Sections = Sections;

  constructor(
    private httpService: HttpService,
    protected settings: AppSettingsService
  ) {}

  ngOnInit() {
    this.questions$ = this.httpService.get<SearchRequestEntities, SearchLimitResult>(
      { method: ApiMethods.SearchLimit },
      {
        entities: 'questions',
        limit: this.limit,
        sortBy: 'views:desc'
      }
    );
  }
}
