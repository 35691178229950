<div class="popular-questions__head">
  <div class="popular-questions__title title-text">{{ 'components.popularQuestions.title' | translate }}</div>
  <a
    ln-button-arrow
    [routerLink]="getSectionLink(Sections.Questions, this.settings.countryCode || '') | localize"
    class="text orange"
  >
    {{ 'components.popularQuestions.buttonViewAll' | translate }}
  </a>
</div>
<div class="popular-questions__body">
  <a
    *ngFor="let item of (questions$ | async)?.data?.questions; let idx = index"
    [routerLink]="getSectionLink(Sections.Questions, item.id, item.alias) | localize"
    class="popular-questions__item"
  >
    <div class="popular-questions__num highlight-text">{{ idx + 1 }}</div>
    <div class="popular-questions__item-content">
      <div class="popular-questions__text" dir="auto">{{ item.title }}</div>
      <div class="popular-questions__bottom">
        <ln-likes-views [likes]="123" [views]="item.views"></ln-likes-views>
      </div>
    </div>
  </a>
</div>
